body,
html,
#root {
    margin: 0;
    overflow: auto;
    background-color: #3b475c;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    max-width: 100vw;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    display: none;
}

::-webkit-scrollbar-thumb {
    background: #7ba0c4;
}